<template>
  <v-layout row justify-space-between>
    <v-flex xs12 sm12 md10 lg6 xl4>
      <v-btn-toggle class="table-top-menu">
        <v-btn flat large :to="{ name: 'settings_page' }">
          Settings
        </v-btn> 

        <v-btn flat large :to="{ name: 'settings_tags' }">
          Tags
        </v-btn>
        <v-btn flat large :to="{ name: 'settings_meme_template' }">
          Meme template
        </v-btn>
      </v-btn-toggle>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "SettingsMenu"
}
</script>

<style scoped>
.table-top-menu {
  width: 100%;
}

.table-top-menu .v-btn {
  height: 48px;
  flex-grow: 1;
}

.v-btn.v-btn--active {
  pointer-events: none;
}
</style>
