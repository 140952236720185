<template>
  <v-container fluid grid-list-xl>
    <settings-menu />

    <router-view />
  </v-container>
</template> 

<script>
import SettingsMenu from "./SettingsMenu"
export default {
  name: "SettingsPage",
  components: {
    SettingsMenu
  }
}
</script>

<style scoped></style>
